import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import React from "react"
import { BookForm } from "../React/BookForm"

export default class EditBook extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target"]
  }

  constructor() {
    super()

    this.jsxRootComponent = () => <BookForm book={this.book} updateBook={(book) => this.update(book)} />
  }

  /**
   * @param {import("../Types").Book} book
   */
  async update(book) {
    try {
      const token = await window.authorise()

      const updatedBook = await fetch(`${this.getAttribute("target")}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(book),
      })

      if (!updatedBook.ok) {
        throw Error("Failed to update book")
      }

      this.book = await updatedBook.json()

      this.onSuccess && this.onSuccess("Book updated")
    } catch (error) {
      this.onError && this.onError("Failed to update book")
    }
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (name === "target") {
      const token = await window.authorise()

      const bookResponse = await fetch(`${this.getAttribute("target")}`, {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      })

      if (!bookResponse.ok) {
        throw Error("Failed to get book")
      }

      this.book = await bookResponse.json()

      this.render()
    }
  }
}

customElements.define("edit-book", EditBook)
